import {
  useRive,
  Layout,
  Fit,
  Alignment,
  useStateMachineInput,
} from "@rive-app/react-canvas";
import { useEffect } from "react";

// Wrapper component to isolate useRive logic that
// renders the <RiveComponent />
const RiveWrapper = () => {
  const { rive, RiveComponent } = useRive({
    src: require("./img/ball_loader.riv"),

    autoplay: true,
  });

  const input = useStateMachineInput(rive, "");

  useEffect(() => {
    if (rive) {
      console.log("Rive instance is ready.");
      // Perform actions with the 'rive' instance here
    }
  }, [rive]);

  return <RiveComponent className="md:h-2/3 h-full" />;
};

export default RiveWrapper;
