import React from "react";

const Home = () => {
  return (
    <div className="flex h-full sm:text-4xl text-2xl items-center justify-center align-middle text-center transition-all duration-1000">
      For those who find inspiration under the moonlight
    </div>
  );
};

export default Home;
