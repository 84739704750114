const Question = ({ question, value, onChange }) => {
  if (!question) {
    return <div>Loading question...</div>; // Or handle the undefined state appropriately
  }

  const handleInputChange = (e) => {
    const optionValue = e.target.value;
    if (question.type === "open") {
      onChange(question.id, optionValue);
    } else {
      let newValue;
      if (e.target.checked) {
        // Add checked option to the array
        newValue = [...value, optionValue];
      } else {
        // Remove unchecked option from the array
        newValue = value.filter((item) => item !== optionValue);
      }
      onChange(question.id, newValue);
    }
  };

  return (
    <div className="w-full animate-slide-in-bottom-fast" key={question.id}>
      <div>
        <h2 className="mb-2 text-4xl font-bold text-white-100 animate-slide-in-bottom">
          {question.text}
        </h2>
        {question.description && (
          <p className="mb-6 text-1xl text-white-50">{question.description}</p>
        )}
        {question.type === "open" ? (
          <input
            type="text"
            value={value}
            onChange={handleInputChange}
            className="bg-transparent border-b-2  mt-4 border-white w-full outline-none text-2xl text-white"
          />
        ) : (
          question.options &&
          question.options.map((option) => (
            <label
              key={option.id}
              className="flex flex-col mb-4 ml-8 text-black"
            >
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name={question.id}
                  value={option.text}
                  checked={value.includes(option.text)}
                  onChange={handleInputChange}
                  className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="text-lg text-white-700">{option.text}</span>
              </div>
            </label>
          ))
        )}
      </div>
    </div>
  );
};

export default Question;
