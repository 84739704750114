import React, { useState } from "react";
import Question from "./Question";

const FormTemplate = ({ questions, onSubmit }) => {
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleChange = (questionId, answer) => {
    setAnswers((prev) => ({ ...prev, [questionId]: answer }));
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else {
      // Check if all questions have been answered
      if (Object.keys(answers).length === questions.length) {
        let isFilled = true;
        for (let question of questions) {
          const answer = answers[question.id];

          // Check for different types of answers
          if (question.type === "open") {
            // For 'open' type, answer should be a non-empty string
            if (!answer || answer.trim() === "") {
              isFilled = false;
              alert(
                `Question ${question.id} is unanswered. Please answer all questions.`
              );
              break;
            }
          } else if (question.type === "closed") {
            // For 'closed' type, answer should be a non-empty array
            if (!Array.isArray(answer) || answer.length === 0) {
              isFilled = false;
              alert(
                `Question ${question.id} is unanswered. Please answer all questions.`
              );
              break;
            }
          }
          // Add additional conditions for other question types if any
        }
        if (isFilled) {
          onSubmit(answers); // Submit the form
        }
      } else {
        alert("Please answer all questions before submitting.");
      }
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    }
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="animate-slide-in-bottom-fast" key={currentQuestionIndex}>
      <Question
        question={currentQuestion}
        value={answers[currentQuestion.id] || ""}
        onChange={handleChange}
        className=""
      />
      <div className="flex flex-row gap-6 ml-1 mt-6 text-green-900 font-medium  text-1xl">
        <button
          onClick={handlePrevious}
          disabled={currentQuestionIndex === 0}
          className="disabled:opacity-10  outline outline-1 hover:outline-2 outline-gray-400 hover:text-white py-2 px-4 rounded-md font-bold text-gray-300"
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          className="//dif state for submit and coninue
        bg-teal-800 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded-md
        "
        >
          {currentQuestionIndex === questions.length - 1
            ? "Submit"
            : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default FormTemplate;
