import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-row p-6 px-10 items-center justify-between align-middle">
      <button className="font-bold font-sans" onClick={() => navigate("/")}>
        eleven59
      </button>
      <button
        className="bg-teal-700 px-4 py-2 rounded-xl text-sm hover:bg-teal-500"
        onClick={() => navigate("/invite")}
      >
        Get started
      </button>
    </div>
  );
};

export default Navbar;
