
var Rive = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  
  return (
function(moduleArg = {}) {

var h=moduleArg,aa,ba;h.ready=new Promise((b,a)=>{aa=b;ba=a});
function ca(){function b(m){const l=d;c=a=0;d=new Map;l.forEach(n=>{try{n(m)}catch(k){console.error(k)}});this.hb();e&&e.Fb()}let a=0,c=0,d=new Map,e=null,g=null;this.requestAnimationFrame=function(m){a||(a=requestAnimationFrame(b.bind(this)));const l=++c;d.set(l,m);return l};this.cancelAnimationFrame=function(m){d.delete(m);a&&0==d.size&&(cancelAnimationFrame(a),a=0)};this.Db=function(m){g&&(document.body.remove(g),g=null);m||(g=document.createElement("div"),g.style.backgroundColor="black",g.style.position=
"fixed",g.style.right=0,g.style.top=0,g.style.color="white",g.style.padding="4px",g.innerHTML="RIVE FPS",m=function(l){g.innerHTML="RIVE FPS "+l.toFixed(1)},document.body.appendChild(g));e=new function(){let l=0,n=0;this.Fb=function(){var k=performance.now();n?(++l,k-=n,1E3<k&&(m(1E3*l/k),l=n=0)):(n=k,l=0)}}};this.Ab=function(){g&&(document.body.remove(g),g=null);e=null};this.hb=function(){}}
function da(b){console.assert(!0);const a=new Map;let c=-Infinity;this.push=function(d){d=d+((1<<b)-1)>>b;a.has(d)&&clearTimeout(a.get(d));a.set(d,setTimeout(function(){a.delete(d);0==a.length?c=-Infinity:d==c&&(c=Math.max(...a.keys()),console.assert(c<d))},1E3));c=Math.max(d,c);return c<<b}}
const ea="createConicGradient createImageData createLinearGradient createPattern createRadialGradient getContextAttributes getImageData getLineDash getTransform isContextLost isPointInPath isPointInStroke measureText".split(" "),fa=new function(){function b(){if(!a){var f=document.createElement("canvas"),r={alpha:1,depth:0,stencil:0,antialias:0,premultipliedAlpha:1,preserveDrawingBuffer:0,preferLowPowerToHighPerformance:0,failIfMajorPerformanceCaveat:0,enableExtensionsByDefault:1,explicitSwapControl:1,
renderViaOffscreenBackBuffer:1};let q;if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){if(q=f.getContext("webgl",r),c=1,!q)return console.log("No WebGL support. Image mesh will not be drawn."),!1}else if(q=f.getContext("webgl2",r))c=2;else if(q=f.getContext("webgl",r))c=1;else return console.log("No WebGL support. Image mesh will not be drawn."),!1;q=new Proxy(q,{get(D,u){if(D.isContextLost()){if(n||(console.error("Cannot render the mesh because the GL Context was lost. Tried to invoke ",u),n=!0),
"function"===typeof D[u])return function(){}}else return"function"===typeof D[u]?function(...I){return D[u].apply(D,I)}:D[u]},set(D,u,I){if(D.isContextLost())n||(console.error("Cannot render the mesh because the GL Context was lost. Tried to set property "+u),n=!0);else return D[u]=I,!0}});d=Math.min(q.getParameter(q.MAX_RENDERBUFFER_SIZE),q.getParameter(q.MAX_TEXTURE_SIZE));function G(D,u,I){u=q.createShader(u);q.shaderSource(u,I);q.compileShader(u);I=q.getShaderInfoLog(u);if(0<(I||"").length)throw I;
q.attachShader(D,u)}f=q.createProgram();G(f,q.VERTEX_SHADER,"attribute vec2 vertex;\n                attribute vec2 uv;\n                uniform vec4 mat;\n                uniform vec2 translate;\n                varying vec2 st;\n                void main() {\n                    st = uv;\n                    gl_Position = vec4(mat2(mat) * vertex + translate, 0, 1);\n                }");G(f,q.FRAGMENT_SHADER,"precision highp float;\n                uniform sampler2D image;\n                varying vec2 st;\n                void main() {\n                    gl_FragColor = texture2D(image, st);\n                }");
q.bindAttribLocation(f,0,"vertex");q.bindAttribLocation(f,1,"uv");q.linkProgram(f);r=q.getProgramInfoLog(f);if(0<(r||"").trim().length)throw r;e=q.getUniformLocation(f,"mat");g=q.getUniformLocation(f,"translate");q.useProgram(f);q.bindBuffer(q.ARRAY_BUFFER,q.createBuffer());q.enableVertexAttribArray(0);q.enableVertexAttribArray(1);q.bindBuffer(q.ELEMENT_ARRAY_BUFFER,q.createBuffer());q.uniform1i(q.getUniformLocation(f,"image"),0);q.pixelStorei(q.UNPACK_PREMULTIPLY_ALPHA_WEBGL,!0);a=q}return!0}let a=
null,c=0,d=0,e=null,g=null,m=0,l=0,n=!1;b();this.Ob=function(){b();return d};this.yb=function(f){a.deleteTexture&&a.deleteTexture(f)};this.wb=function(f){if(!b())return null;const r=a.createTexture();if(!r)return null;a.bindTexture(a.TEXTURE_2D,r);a.texImage2D(a.TEXTURE_2D,0,a.RGBA,a.RGBA,a.UNSIGNED_BYTE,f);a.texParameteri(a.TEXTURE_2D,a.TEXTURE_WRAP_S,a.CLAMP_TO_EDGE);a.texParameteri(a.TEXTURE_2D,a.TEXTURE_WRAP_T,a.CLAMP_TO_EDGE);a.texParameteri(a.TEXTURE_2D,a.TEXTURE_MAG_FILTER,a.LINEAR);2==c?(a.texParameteri(a.TEXTURE_2D,
a.TEXTURE_MIN_FILTER,a.LINEAR_MIPMAP_LINEAR),a.generateMipmap(a.TEXTURE_2D)):a.texParameteri(a.TEXTURE_2D,a.TEXTURE_MIN_FILTER,a.LINEAR);return r};const k=new da(8),t=new da(8),v=new da(10),x=new da(10);this.Cb=function(f,r,q,G,D){if(b()){var u=k.push(f),I=t.push(r);if(a.canvas){if(a.canvas.width!=u||a.canvas.height!=I)a.canvas.width=u,a.canvas.height=I;a.viewport(0,I-r,f,r);a.disable(a.SCISSOR_TEST);a.clearColor(0,0,0,0);a.clear(a.COLOR_BUFFER_BIT);a.enable(a.SCISSOR_TEST);q.sort((A,W)=>W.lb-A.lb);
u=v.push(G);m!=u&&(a.bufferData(a.ARRAY_BUFFER,8*u,a.DYNAMIC_DRAW),m=u);u=0;for(var O of q)a.bufferSubData(a.ARRAY_BUFFER,u,O.Sa),u+=4*O.Sa.length;console.assert(u==4*G);for(var X of q)a.bufferSubData(a.ARRAY_BUFFER,u,X.ob),u+=4*X.ob.length;console.assert(u==8*G);u=x.push(D);l!=u&&(a.bufferData(a.ELEMENT_ARRAY_BUFFER,2*u,a.DYNAMIC_DRAW),l=u);O=0;for(var ia of q)a.bufferSubData(a.ELEMENT_ARRAY_BUFFER,O,ia.indices),O+=2*ia.indices.length;console.assert(O==2*D);ia=0;X=!0;u=O=0;for(const A of q){A.image.Na!=
ia&&(a.bindTexture(a.TEXTURE_2D,A.image.Ma||null),ia=A.image.Na);A.Rb?(a.scissor(A.Xa,I-A.Ya-A.fb,A.bc,A.fb),X=!0):X&&(a.scissor(0,I-r,f,r),X=!1);q=2/f;const W=-2/r;a.uniform4f(e,A.xa[0]*q*A.Ga,A.xa[1]*W*A.Ha,A.xa[2]*q*A.Ga,A.xa[3]*W*A.Ha);a.uniform2f(g,A.xa[4]*q*A.Ga+q*(A.Xa-A.Pb*A.Ga)-1,A.xa[5]*W*A.Ha+W*(A.Ya-A.Qb*A.Ha)+1);a.vertexAttribPointer(0,2,a.FLOAT,!1,0,u);a.vertexAttribPointer(1,2,a.FLOAT,!1,0,u+4*G);a.drawElements(a.TRIANGLES,A.indices.length,a.UNSIGNED_SHORT,O);u+=4*A.Sa.length;O+=2*
A.indices.length}console.assert(u==4*G);console.assert(O==2*D)}}};this.canvas=function(){return b()&&a.canvas}},ha=h.onRuntimeInitialized;
h.onRuntimeInitialized=function(){function b(p){switch(p){case k.srcOver:return"source-over";case k.screen:return"screen";case k.overlay:return"overlay";case k.darken:return"darken";case k.lighten:return"lighten";case k.colorDodge:return"color-dodge";case k.colorBurn:return"color-burn";case k.hardLight:return"hard-light";case k.softLight:return"soft-light";case k.difference:return"difference";case k.exclusion:return"exclusion";case k.multiply:return"multiply";case k.hue:return"hue";case k.saturation:return"saturation";
case k.color:return"color";case k.luminosity:return"luminosity"}}function a(p){return"rgba("+((16711680&p)>>>16)+","+((65280&p)>>>8)+","+((255&p)>>>0)+","+((4278190080&p)>>>24)/255+")"}function c(){0<I.length&&(fa.Cb(u.drawWidth(),u.drawHeight(),I,O,X),I=[],X=O=0,u.reset(512,512));for(const p of D){for(const w of p.ja)w();p.ja=[]}D.clear()}ha&&ha();var d=h.RenderPaintStyle;const e=h.RenderPath,g=h.RenderPaint,m=h.Renderer,l=h.StrokeCap,n=h.StrokeJoin,k=h.BlendMode,t=d.fill,v=d.stroke,x=h.FillRule.evenOdd;
let f=1;var r=h.RenderImage.extend("CanvasRenderImage",{__construct:function({Aa:p,Fa:w}={}){this.__parent.__construct.call(this);this.Na=f;f=f+1&2147483647||1;this.Aa=p;this.Fa=w},__destruct:function(){this.Ma&&(fa.yb(this.Ma),URL.revokeObjectURL(this.Va));this.__parent.__destruct.call(this)},decode:function(p){var w=this;w.Fa&&w.Fa(w);var B=new Image;w.Va=URL.createObjectURL(new Blob([p],{type:"image/png"}));B.src=w.Va;B.onload=function(){w.qb=B;w.Ma=fa.wb(B);w.size(B.width,B.height);w.Aa&&w.Aa(w)}}}),
q=e.extend("CanvasRenderPath",{__construct:function(){this.__parent.__construct.call(this);this.pa=new Path2D},rewind:function(){this.pa=new Path2D},addPath:function(p,w,B,C,z,E,F){var H=this.pa,T=H.addPath;p=p.pa;const K=new DOMMatrix;K.a=w;K.b=B;K.c=C;K.d=z;K.e=E;K.f=F;T.call(H,p,K)},fillRule:function(p){this.Ua=p},moveTo:function(p,w){this.pa.moveTo(p,w)},lineTo:function(p,w){this.pa.lineTo(p,w)},cubicTo:function(p,w,B,C,z,E){this.pa.bezierCurveTo(p,w,B,C,z,E)},close:function(){this.pa.closePath()}}),
G=g.extend("CanvasRenderPaint",{color:function(p){this.Wa=a(p)},thickness:function(p){this.tb=p},join:function(p){switch(p){case n.miter:this.La="miter";break;case n.round:this.La="round";break;case n.bevel:this.La="bevel"}},cap:function(p){switch(p){case l.butt:this.Ka="butt";break;case l.round:this.Ka="round";break;case l.square:this.Ka="square"}},style:function(p){this.sb=p},blendMode:function(p){this.pb=b(p)},clearGradient:function(){this.za=null},linearGradient:function(p,w,B,C){this.za={mb:p,
nb:w,ab:B,bb:C,Qa:[]}},radialGradient:function(p,w,B,C){this.za={mb:p,nb:w,ab:B,bb:C,Qa:[],Mb:!0}},addStop:function(p,w){this.za.Qa.push({color:p,stop:w})},completeGradient:function(){},draw:function(p,w,B){let C=this.sb;var z=this.Wa,E=this.za;p.globalCompositeOperation=this.pb;if(null!=E){z=E.mb;var F=E.nb;const T=E.ab;var H=E.bb;const K=E.Qa;E.Mb?(E=T-z,H-=F,z=p.createRadialGradient(z,F,0,z,F,Math.sqrt(E*E+H*H))):z=p.createLinearGradient(z,F,T,H);for(let Y=0,N=K.length;Y<N;Y++)F=K[Y],z.addColorStop(F.stop,
a(F.color));this.Wa=z;this.za=null}switch(C){case v:p.strokeStyle=z;p.lineWidth=this.tb;p.lineCap=this.Ka;p.lineJoin=this.La;p.stroke(w);break;case t:p.fillStyle=z,p.fill(w,B)}}});const D=new Set;let u=null,I=[],O=0,X=0;var ia=h.CanvasRenderer=m.extend("Renderer",{__construct:function(p){this.__parent.__construct.call(this);this.oa=[1,0,0,1,0,0];this.ha=p.getContext("2d");this.Ta=p;this.ja=[]},save:function(){this.oa.push(...this.oa.slice(this.oa.length-6));this.ja.push(this.ha.save.bind(this.ha))},
restore:function(){const p=this.oa.length-6;if(6>p)throw"restore() called without matching save().";this.oa.splice(p);this.ja.push(this.ha.restore.bind(this.ha))},transform:function(p,w,B,C,z,E){const F=this.oa,H=F.length-6;F.splice(H,6,F[H]*p+F[H+2]*w,F[H+1]*p+F[H+3]*w,F[H]*B+F[H+2]*C,F[H+1]*B+F[H+3]*C,F[H]*z+F[H+2]*E+F[H+4],F[H+1]*z+F[H+3]*E+F[H+5]);this.ja.push(this.ha.transform.bind(this.ha,p,w,B,C,z,E))},rotate:function(p){const w=Math.sin(p);p=Math.cos(p);this.transform(p,w,-w,p,0,0)},_drawPath:function(p,
w){this.ja.push(w.draw.bind(w,this.ha,p.pa,p.Ua===x?"evenodd":"nonzero"))},_drawRiveImage:function(p,w,B){var C=p.qb;if(C){var z=this.ha,E=b(w);this.ja.push(function(){z.globalCompositeOperation=E;z.globalAlpha=B;z.drawImage(C,0,0);z.globalAlpha=1})}},_getMatrix:function(p){const w=this.oa,B=w.length-6;for(let C=0;6>C;++C)p[C]=w[B+C]},_drawImageMesh:function(p,w,B,C,z,E,F,H,T,K){var Y=this.ha.canvas.width,N=this.ha.canvas.height;const sb=T-F,tb=K-H;F=Math.max(F,0);H=Math.max(H,0);T=Math.min(T,Y);
K=Math.min(K,N);const wa=T-F,xa=K-H;console.assert(wa<=Math.min(sb,Y));console.assert(xa<=Math.min(tb,N));if(!(0>=wa||0>=xa)){T=wa<sb||xa<tb;Y=K=1;var ja=Math.ceil(wa*K),ka=Math.ceil(xa*Y);N=fa.Ob();ja>N&&(K*=N/ja,ja=N);ka>N&&(Y*=N/ka,ka=N);u||(u=new h.DynamicRectanizer(N),u.reset(512,512));N=u.addRect(ja,ka);0>N&&(c(),D.add(this),N=u.addRect(ja,ka),console.assert(0<=N));var ub=N&65535,vb=N>>16;I.push({xa:this.oa.slice(this.oa.length-6),image:p,Xa:ub,Ya:vb,Pb:F,Qb:H,bc:ja,fb:ka,Ga:K,Ha:Y,Sa:new Float32Array(C),
ob:new Float32Array(z),indices:new Uint16Array(E),Rb:T,lb:p.Na<<1|(T?1:0)});O+=C.length;X+=E.length;var pa=this.ha,lc=b(w);this.ja.push(function(){pa.save();pa.resetTransform();pa.globalCompositeOperation=lc;pa.globalAlpha=B;const wb=fa.canvas();wb&&pa.drawImage(wb,ub,vb,ja,ka,F,H,wa,xa);pa.restore()})}},_clipPath:function(p){this.ja.push(this.ha.clip.bind(this.ha,p.pa,p.Ua===x?"evenodd":"nonzero"))},clear:function(){D.add(this);this.ja.push(this.ha.clearRect.bind(this.ha,0,0,this.Ta.width,this.Ta.height))},
flush:function(){},translate:function(p,w){this.transform(1,0,0,1,p,w)}});h.makeRenderer=function(p){const w=new ia(p),B=w.ha;return new Proxy(w,{get(C,z){if("function"===typeof C[z])return function(...E){return C[z].apply(C,E)};if("function"===typeof B[z]){if(-1<ea.indexOf(z))throw Error("RiveException: Method call to '"+z+"()' is not allowed, as the renderer cannot immediately pass through the return                 values of any canvas 2d context methods.");return function(...E){w.ja.push(B[z].bind(B,
...E))}}return C[z]},set(C,z,E){if(z in B)return B[z]=E,!0}})};h.decodeImage=function(p,w){(new r({Aa:w})).decode(p)};h.renderFactory={makeRenderPaint:function(){return new G},makeRenderPath:function(){return new q},makeRenderImage:function(){let p=W;return new r({Fa:()=>{p.total++},Aa:()=>{p.loaded++;if(p.loaded===p.total){const w=p.ready;w&&(w(),p.ready=null)}}})}};let A=h.load,W=null;h.load=function(p,w,B=!0){const C=new h.FallbackFileAssetLoader;void 0!==w&&C.addLoader(w);B&&(w=new h.CDNFileAssetLoader,
C.addLoader(w));return new Promise(function(z){let E=null;W={total:0,loaded:0,ready:function(){z(E)}};E=A(p,C);0==W.total&&z(E)})};d=new ca;h.requestAnimationFrame=d.requestAnimationFrame.bind(d);h.cancelAnimationFrame=d.cancelAnimationFrame.bind(d);h.enableFPSCounter=d.Db.bind(d);h.disableFPSCounter=d.Ab;d.hb=c;h.cleanup=function(){u&&u.delete()}};const la=h.onRuntimeInitialized;
h.onRuntimeInitialized=function(){la&&la();let b=h.decodeFont;h.decodeFont=function(c,d){c=b(c);d(c)};const a=h.FileAssetLoader;h.ptrToAsset=c=>{let d=h.ptrToFileAsset(c);return d.isImage?h.ptrToImageAsset(c):d.isFont?h.ptrToFontAsset(c):d};h.CustomFileAssetLoader=a.extend("CustomFileAssetLoader",{__construct:function({loadContents:c}){this.__parent.__construct.call(this);this.rb=c},loadContents:function(c,d){c=h.ptrToAsset(c);return this.rb(c,d)}});h.CDNFileAssetLoader=a.extend("CDNFileAssetLoader",
{__construct:function(){this.__parent.__construct.call(this)},loadContents:function(c){let d=h.ptrToAsset(c);c=d.cdnUuid;if(""===c)return!1;(function(e,g){var m=new XMLHttpRequest;m.responseType="arraybuffer";m.onreadystatechange=function(){4==m.readyState&&200==m.status&&g(m)};m.open("GET",e,!0);m.send(null)})(d.cdnBaseUrl+"/"+c,e=>{d.decode(new Uint8Array(e.response))});return!0}});h.FallbackFileAssetLoader=a.extend("FallbackFileAssetLoader",{__construct:function(){this.__parent.__construct.call(this);
this.gb=[]},addLoader:function(c){this.gb.push(c)},loadContents:function(c,d){for(let e of this.gb)if(e.loadContents(c,d))return!0;return!1}})};var ma=Object.assign({},h),na="./this.program",oa="object"==typeof window,qa="function"==typeof importScripts,y="",ra,sa;
if(oa||qa)qa?y=self.location.href:"undefined"!=typeof document&&document.currentScript&&(y=document.currentScript.src),_scriptDir&&(y=_scriptDir),0!==y.indexOf("blob:")?y=y.substr(0,y.replace(/[?#].*/,"").lastIndexOf("/")+1):y="",qa&&(sa=b=>{var a=new XMLHttpRequest;a.open("GET",b,!1);a.responseType="arraybuffer";a.send(null);return new Uint8Array(a.response)}),ra=(b,a,c)=>{var d=new XMLHttpRequest;d.open("GET",b,!0);d.responseType="arraybuffer";d.onload=()=>{200==d.status||0==d.status&&d.response?
a(d.response):c()};d.onerror=c;d.send(null)};var ta=h.print||console.log.bind(console),ua=h.printErr||console.error.bind(console);Object.assign(h,ma);ma=null;h.thisProgram&&(na=h.thisProgram);var va;h.wasmBinary&&(va=h.wasmBinary);var noExitRuntime=h.noExitRuntime||!0;"object"!=typeof WebAssembly&&ya("no native wasm support detected");var za,J,Aa=!1,Ba,L,Ca,Da,M,P,Ea,Fa;
function Ga(){var b=za.buffer;h.HEAP8=Ba=new Int8Array(b);h.HEAP16=Ca=new Int16Array(b);h.HEAP32=M=new Int32Array(b);h.HEAPU8=L=new Uint8Array(b);h.HEAPU16=Da=new Uint16Array(b);h.HEAPU32=P=new Uint32Array(b);h.HEAPF32=Ea=new Float32Array(b);h.HEAPF64=Fa=new Float64Array(b)}var Ha,Ia=[],Ja=[],Ka=[];function La(){var b=h.preRun.shift();Ia.unshift(b)}var Ma=0,Na=null,Oa=null;
function ya(b){if(h.onAbort)h.onAbort(b);b="Aborted("+b+")";ua(b);Aa=!0;b=new WebAssembly.RuntimeError(b+". Build with -sASSERTIONS for more info.");ba(b);throw b;}function Pa(b){return b.startsWith("data:application/octet-stream;base64,")}var Qa;Qa="canvas_advanced.wasm";if(!Pa(Qa)){var Ra=Qa;Qa=h.locateFile?h.locateFile(Ra,y):y+Ra}function Sa(b){if(b==Qa&&va)return new Uint8Array(va);if(sa)return sa(b);throw"both async and sync fetching of the wasm failed";}
function Ta(b){if(!va&&(oa||qa)){if("function"==typeof fetch&&!b.startsWith("file://"))return fetch(b,{credentials:"same-origin"}).then(a=>{if(!a.ok)throw"failed to load wasm binary file at '"+b+"'";return a.arrayBuffer()}).catch(()=>Sa(b));if(ra)return new Promise((a,c)=>{ra(b,d=>a(new Uint8Array(d)),c)})}return Promise.resolve().then(()=>Sa(b))}function Ua(b,a,c){return Ta(b).then(d=>WebAssembly.instantiate(d,a)).then(d=>d).then(c,d=>{ua("failed to asynchronously prepare wasm: "+d);ya(d)})}
function Va(b,a){var c=Qa;return va||"function"!=typeof WebAssembly.instantiateStreaming||Pa(c)||c.startsWith("file://")||"function"!=typeof fetch?Ua(c,b,a):fetch(c,{credentials:"same-origin"}).then(d=>WebAssembly.instantiateStreaming(d,b).then(a,function(e){ua("wasm streaming compile failed: "+e);ua("falling back to ArrayBuffer instantiation");return Ua(c,b,a)}))}var Wa=b=>{for(;0<b.length;)b.shift()(h)};
function Xa(b){if(void 0===b)return"_unknown";b=b.replace(/[^a-zA-Z0-9_]/g,"$");var a=b.charCodeAt(0);return 48<=a&&57>=a?`_${b}`:b}function Ya(b,a){b=Xa(b);return{[b]:function(){return a.apply(this,arguments)}}[b]}function Za(){this.qa=[void 0];this.eb=[]}var Q=new Za,$a=void 0;function R(b){throw new $a(b);}
var S=b=>{b||R("Cannot use deleted val. handle = "+b);return Q.get(b).value},ab=b=>{switch(b){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:return Q.ub({kb:1,value:b})}};
function bb(b){var a=Error,c=Ya(b,function(d){this.name=b;this.message=d;d=Error(d).stack;void 0!==d&&(this.stack=this.toString()+"\n"+d.replace(/^Error(:[^\n]*)?\n/,""))});c.prototype=Object.create(a.prototype);c.prototype.constructor=c;c.prototype.toString=function(){return void 0===this.message?this.name:`${this.name}: ${this.message}`};return c}var cb=void 0,db=void 0;function U(b){for(var a="";L[b];)a+=db[L[b++]];return a}var eb=[];
function fb(){for(;eb.length;){var b=eb.pop();b.ba.wa=!1;b["delete"]()}}var gb=void 0,hb={};function ib(b,a){for(void 0===a&&R("ptr should not be undefined");b.fa;)a=b.Ba(a),b=b.fa;return a}var jb={};function kb(b){b=lb(b);var a=U(b);mb(b);return a}function nb(b,a){var c=jb[b];void 0===c&&R(a+" has unknown type "+kb(b));return c}function ob(){}var pb=!1;function qb(b){--b.count.value;0===b.count.value&&(b.ia?b.la.ra(b.ia):b.ea.ca.ra(b.da))}
function rb(b,a,c){if(a===c)return b;if(void 0===c.fa)return null;b=rb(b,a,c.fa);return null===b?null:c.Bb(b)}var xb={};function yb(b,a){a=ib(b,a);return hb[a]}var zb=void 0;function Ab(b){throw new zb(b);}function Bb(b,a){a.ea&&a.da||Ab("makeClassHandle requires ptr and ptrType");!!a.la!==!!a.ia&&Ab("Both smartPtrType and smartPtr must be specified");a.count={value:1};return Cb(Object.create(b,{ba:{value:a}}))}
function Cb(b){if("undefined"===typeof FinalizationRegistry)return Cb=a=>a,b;pb=new FinalizationRegistry(a=>{qb(a.ba)});Cb=a=>{var c=a.ba;c.ia&&pb.register(a,{ba:c},a);return a};ob=a=>{pb.unregister(a)};return Cb(b)}var Db={};function Eb(b){for(;b.length;){var a=b.pop();b.pop()(a)}}function Fb(b){return this.fromWireType(M[b>>2])}var Gb={},Hb={};
function V(b,a,c){function d(l){l=c(l);l.length!==b.length&&Ab("Mismatched type converter count");for(var n=0;n<b.length;++n)Ib(b[n],l[n])}b.forEach(function(l){Hb[l]=a});var e=Array(a.length),g=[],m=0;a.forEach((l,n)=>{jb.hasOwnProperty(l)?e[n]=jb[l]:(g.push(l),Gb.hasOwnProperty(l)||(Gb[l]=[]),Gb[l].push(()=>{e[n]=jb[l];++m;m===g.length&&d(e)}))});0===g.length&&d(e)}
function Jb(b){switch(b){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError(`Unknown type size: ${b}`);}}function Kb(b,a,c={}){var d=a.name;b||R(`type "${d}" must have a positive integer typeid pointer`);if(jb.hasOwnProperty(b)){if(c.Lb)return;R(`Cannot register type '${d}' twice`)}jb[b]=a;delete Hb[b];Gb.hasOwnProperty(b)&&(a=Gb[b],delete Gb[b],a.forEach(e=>e()))}
function Ib(b,a,c={}){if(!("argPackAdvance"in a))throw new TypeError("registerType registeredInstance requires argPackAdvance");Kb(b,a,c)}function Lb(b){R(b.ba.ea.ca.name+" instance already deleted")}function Mb(){}
function Nb(b,a,c){if(void 0===b[a].ga){var d=b[a];b[a]=function(){b[a].ga.hasOwnProperty(arguments.length)||R(`Function '${c}' called with an invalid number of arguments (${arguments.length}) - expects one of (${b[a].ga})!`);return b[a].ga[arguments.length].apply(this,arguments)};b[a].ga=[];b[a].ga[d.va]=d}}
function Ob(b,a,c){h.hasOwnProperty(b)?((void 0===c||void 0!==h[b].ga&&void 0!==h[b].ga[c])&&R(`Cannot register public name '${b}' twice`),Nb(h,b,b),h.hasOwnProperty(c)&&R(`Cannot register multiple overloads of a function with the same number of arguments (${c})!`),h[b].ga[c]=a):(h[b]=a,void 0!==c&&(h[b].cc=c))}function Pb(b,a,c,d,e,g,m,l){this.name=b;this.constructor=a;this.ma=c;this.ra=d;this.fa=e;this.Gb=g;this.Ba=m;this.Bb=l;this.ib=[]}
function Qb(b,a,c){for(;a!==c;)a.Ba||R(`Expected null or instance of ${c.name}, got an instance of ${a.name}`),b=a.Ba(b),a=a.fa;return b}function Rb(b,a){if(null===a)return this.Oa&&R(`null is not a valid ${this.name}`),0;a.ba||R(`Cannot pass "${Sb(a)}" as a ${this.name}`);a.ba.da||R(`Cannot pass deleted object as a pointer of type ${this.name}`);return Qb(a.ba.da,a.ba.ea.ca,this.ca)}
function Tb(b,a){if(null===a){this.Oa&&R(`null is not a valid ${this.name}`);if(this.Ea){var c=this.Pa();null!==b&&b.push(this.ra,c);return c}return 0}a.ba||R(`Cannot pass "${Sb(a)}" as a ${this.name}`);a.ba.da||R(`Cannot pass deleted object as a pointer of type ${this.name}`);!this.Da&&a.ba.ea.Da&&R(`Cannot convert argument of type ${a.ba.la?a.ba.la.name:a.ba.ea.name} to parameter type ${this.name}`);c=Qb(a.ba.da,a.ba.ea.ca,this.ca);if(this.Ea)switch(void 0===a.ba.ia&&R("Passing raw pointer to smart pointer is illegal"),
this.Xb){case 0:a.ba.la===this?c=a.ba.ia:R(`Cannot convert argument of type ${a.ba.la?a.ba.la.name:a.ba.ea.name} to parameter type ${this.name}`);break;case 1:c=a.ba.ia;break;case 2:if(a.ba.la===this)c=a.ba.ia;else{var d=a.clone();c=this.Tb(c,ab(function(){d["delete"]()}));null!==b&&b.push(this.ra,c)}break;default:R("Unsupporting sharing policy")}return c}
function Ub(b,a){if(null===a)return this.Oa&&R(`null is not a valid ${this.name}`),0;a.ba||R(`Cannot pass "${Sb(a)}" as a ${this.name}`);a.ba.da||R(`Cannot pass deleted object as a pointer of type ${this.name}`);a.ba.ea.Da&&R(`Cannot convert argument of type ${a.ba.ea.name} to parameter type ${this.name}`);return Qb(a.ba.da,a.ba.ea.ca,this.ca)}
function Vb(b,a,c,d){this.name=b;this.ca=a;this.Oa=c;this.Da=d;this.Ea=!1;this.ra=this.Tb=this.Pa=this.jb=this.Xb=this.Sb=void 0;void 0!==a.fa?this.toWireType=Tb:(this.toWireType=d?Rb:Ub,this.ka=null)}function Wb(b,a,c){h.hasOwnProperty(b)||Ab("Replacing nonexistant public symbol");void 0!==h[b].ga&&void 0!==c?h[b].ga[c]=a:(h[b]=a,h[b].va=c)}
var Xb=(b,a)=>{var c=[];return function(){c.length=0;Object.assign(c,arguments);if(b.includes("j")){var d=h["dynCall_"+b];d=c&&c.length?d.apply(null,[a].concat(c)):d.call(null,a)}else d=Ha.get(a).apply(null,c);return d}};function Z(b,a){b=U(b);var c=b.includes("j")?Xb(b,a):Ha.get(a);"function"!=typeof c&&R(`unknown function pointer with signature ${b}: ${a}`);return c}var Yb=void 0;
function Zb(b,a){function c(g){e[g]||jb[g]||(Hb[g]?Hb[g].forEach(c):(d.push(g),e[g]=!0))}var d=[],e={};a.forEach(c);throw new Yb(`${b}: `+d.map(kb).join([", "]));}
function $b(b,a,c,d,e){var g=a.length;2>g&&R("argTypes array size mismatch! Must at least get return value and 'this' types!");var m=null!==a[1]&&null!==c,l=!1;for(c=1;c<a.length;++c)if(null!==a[c]&&void 0===a[c].ka){l=!0;break}var n="void"!==a[0].name,k=g-2,t=Array(k),v=[],x=[];return function(){arguments.length!==k&&R(`function ${b} called with ${arguments.length} arguments, expected ${k} args!`);x.length=0;v.length=m?2:1;v[0]=e;if(m){var f=a[1].toWireType(x,this);v[1]=f}for(var r=0;r<k;++r)t[r]=
a[r+2].toWireType(x,arguments[r]),v.push(t[r]);r=d.apply(null,v);if(l)Eb(x);else for(var q=m?1:2;q<a.length;q++){var G=1===q?f:t[q-2];null!==a[q].ka&&a[q].ka(G)}f=n?a[0].fromWireType(r):void 0;return f}}function ac(b,a){for(var c=[],d=0;d<b;d++)c.push(P[a+4*d>>2]);return c}
function bc(b,a,c){b instanceof Object||R(`${c} with invalid "this": ${b}`);b instanceof a.ca.constructor||R(`${c} incompatible with "this" of type ${b.constructor.name}`);b.ba.da||R(`cannot call emscripten binding method ${c} on deleted object`);return Qb(b.ba.da,b.ba.ea.ca,a.ca)}function cc(b){b>=Q.Za&&0===--Q.get(b).kb&&Q.xb(b)}
function dc(b,a,c){switch(a){case 0:return function(d){return this.fromWireType((c?Ba:L)[d])};case 1:return function(d){return this.fromWireType((c?Ca:Da)[d>>1])};case 2:return function(d){return this.fromWireType((c?M:P)[d>>2])};default:throw new TypeError("Unknown integer type: "+b);}}function Sb(b){if(null===b)return"null";var a=typeof b;return"object"===a||"array"===a||"function"===a?b.toString():""+b}
function ec(b,a){switch(a){case 2:return function(c){return this.fromWireType(Ea[c>>2])};case 3:return function(c){return this.fromWireType(Fa[c>>3])};default:throw new TypeError("Unknown float type: "+b);}}
function fc(b,a,c){switch(a){case 0:return c?function(d){return Ba[d]}:function(d){return L[d]};case 1:return c?function(d){return Ca[d>>1]}:function(d){return Da[d>>1]};case 2:return c?function(d){return M[d>>2]}:function(d){return P[d>>2]};default:throw new TypeError("Unknown integer type: "+b);}}
var gc=(b,a,c,d)=>{if(0<d){d=c+d-1;for(var e=0;e<b.length;++e){var g=b.charCodeAt(e);if(55296<=g&&57343>=g){var m=b.charCodeAt(++e);g=65536+((g&1023)<<10)|m&1023}if(127>=g){if(c>=d)break;a[c++]=g}else{if(2047>=g){if(c+1>=d)break;a[c++]=192|g>>6}else{if(65535>=g){if(c+2>=d)break;a[c++]=224|g>>12}else{if(c+3>=d)break;a[c++]=240|g>>18;a[c++]=128|g>>12&63}a[c++]=128|g>>6&63}a[c++]=128|g&63}}a[c]=0}},hc=b=>{for(var a=0,c=0;c<b.length;++c){var d=b.charCodeAt(c);127>=d?a++:2047>=d?a+=2:55296<=d&&57343>=
d?(a+=4,++c):a+=3}return a},ic="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0,jc=(b,a,c)=>{var d=a+c;for(c=a;b[c]&&!(c>=d);)++c;if(16<c-a&&b.buffer&&ic)return ic.decode(b.subarray(a,c));for(d="";a<c;){var e=b[a++];if(e&128){var g=b[a++]&63;if(192==(e&224))d+=String.fromCharCode((e&31)<<6|g);else{var m=b[a++]&63;e=224==(e&240)?(e&15)<<12|g<<6|m:(e&7)<<18|g<<12|m<<6|b[a++]&63;65536>e?d+=String.fromCharCode(e):(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023))}}else d+=String.fromCharCode(e)}return d},
kc="undefined"!=typeof TextDecoder?new TextDecoder("utf-16le"):void 0,mc=(b,a)=>{var c=b>>1;for(var d=c+a/2;!(c>=d)&&Da[c];)++c;c<<=1;if(32<c-b&&kc)return kc.decode(L.subarray(b,c));c="";for(d=0;!(d>=a/2);++d){var e=Ca[b+2*d>>1];if(0==e)break;c+=String.fromCharCode(e)}return c},nc=(b,a,c)=>{void 0===c&&(c=2147483647);if(2>c)return 0;c-=2;var d=a;c=c<2*b.length?c/2:b.length;for(var e=0;e<c;++e)Ca[a>>1]=b.charCodeAt(e),a+=2;Ca[a>>1]=0;return a-d},oc=b=>2*b.length,pc=(b,a)=>{for(var c=0,d="";!(c>=a/
4);){var e=M[b+4*c>>2];if(0==e)break;++c;65536<=e?(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023)):d+=String.fromCharCode(e)}return d},qc=(b,a,c)=>{void 0===c&&(c=2147483647);if(4>c)return 0;var d=a;c=d+c-4;for(var e=0;e<b.length;++e){var g=b.charCodeAt(e);if(55296<=g&&57343>=g){var m=b.charCodeAt(++e);g=65536+((g&1023)<<10)|m&1023}M[a>>2]=g;a+=4;if(a+4>c)break}M[a>>2]=0;return a-d},rc=b=>{for(var a=0,c=0;c<b.length;++c){var d=b.charCodeAt(c);55296<=d&&57343>=d&&++c;a+=4}return a},sc={};
function tc(b){var a=sc[b];return void 0===a?U(b):a}var uc=[];function vc(b){var a=uc.length;uc.push(b);return a}function wc(b,a){for(var c=Array(b),d=0;d<b;++d)c[d]=nb(P[a+4*d>>2],"parameter "+d);return c}
var xc=[],yc={},Ac=()=>{if(!zc){var b={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:na||"./this.program"},a;for(a in yc)void 0===yc[a]?delete b[a]:b[a]=yc[a];var c=[];for(a in b)c.push(`${a}=${b[a]}`);zc=c}return zc},zc,Bc=[null,[],[]],Cc=b=>0===b%4&&(0!==b%100||0===b%400),Dc=[31,29,31,30,31,30,31,31,30,31,30,31],Ec=[31,28,31,30,31,30,31,31,30,31,30,31];
function Fc(b){var a=Array(hc(b)+1);gc(b,a,0,a.length);return a}
var Gc=(b,a,c,d)=>{function e(f,r,q){for(f="number"==typeof f?f.toString():f||"";f.length<r;)f=q[0]+f;return f}function g(f,r){return e(f,r,"0")}function m(f,r){function q(D){return 0>D?-1:0<D?1:0}var G;0===(G=q(f.getFullYear()-r.getFullYear()))&&0===(G=q(f.getMonth()-r.getMonth()))&&(G=q(f.getDate()-r.getDate()));return G}function l(f){switch(f.getDay()){case 0:return new Date(f.getFullYear()-1,11,29);case 1:return f;case 2:return new Date(f.getFullYear(),0,3);case 3:return new Date(f.getFullYear(),
0,2);case 4:return new Date(f.getFullYear(),0,1);case 5:return new Date(f.getFullYear()-1,11,31);case 6:return new Date(f.getFullYear()-1,11,30)}}function n(f){var r=f.ta;for(f=new Date((new Date(f.ua+1900,0,1)).getTime());0<r;){var q=f.getMonth(),G=(Cc(f.getFullYear())?Dc:Ec)[q];if(r>G-f.getDate())r-=G-f.getDate()+1,f.setDate(1),11>q?f.setMonth(q+1):(f.setMonth(0),f.setFullYear(f.getFullYear()+1));else{f.setDate(f.getDate()+r);break}}q=new Date(f.getFullYear()+1,0,4);r=l(new Date(f.getFullYear(),
0,4));q=l(q);return 0>=m(r,f)?0>=m(q,f)?f.getFullYear()+1:f.getFullYear():f.getFullYear()-1}var k=M[d+40>>2];d={$b:M[d>>2],Zb:M[d+4>>2],Ia:M[d+8>>2],Ra:M[d+12>>2],Ja:M[d+16>>2],ua:M[d+20>>2],na:M[d+24>>2],ta:M[d+28>>2],dc:M[d+32>>2],Yb:M[d+36>>2],ac:k?k?jc(L,k):"":""};c=c?jc(L,c):"";k={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y",
"%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var t in k)c=c.replace(new RegExp(t,"g"),k[t]);var v="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),x="January February March April May June July August September October November December".split(" ");k={"%a":f=>v[f.na].substring(0,3),"%A":f=>v[f.na],"%b":f=>x[f.Ja].substring(0,3),"%B":f=>x[f.Ja],"%C":f=>g((f.ua+1900)/
100|0,2),"%d":f=>g(f.Ra,2),"%e":f=>e(f.Ra,2," "),"%g":f=>n(f).toString().substring(2),"%G":f=>n(f),"%H":f=>g(f.Ia,2),"%I":f=>{f=f.Ia;0==f?f=12:12<f&&(f-=12);return g(f,2)},"%j":f=>{for(var r=0,q=0;q<=f.Ja-1;r+=(Cc(f.ua+1900)?Dc:Ec)[q++]);return g(f.Ra+r,3)},"%m":f=>g(f.Ja+1,2),"%M":f=>g(f.Zb,2),"%n":()=>"\n","%p":f=>0<=f.Ia&&12>f.Ia?"AM":"PM","%S":f=>g(f.$b,2),"%t":()=>"\t","%u":f=>f.na||7,"%U":f=>g(Math.floor((f.ta+7-f.na)/7),2),"%V":f=>{var r=Math.floor((f.ta+7-(f.na+6)%7)/7);2>=(f.na+371-f.ta-
2)%7&&r++;if(r)53==r&&(q=(f.na+371-f.ta)%7,4==q||3==q&&Cc(f.ua)||(r=1));else{r=52;var q=(f.na+7-f.ta-1)%7;(4==q||5==q&&Cc(f.ua%400-1))&&r++}return g(r,2)},"%w":f=>f.na,"%W":f=>g(Math.floor((f.ta+7-(f.na+6)%7)/7),2),"%y":f=>(f.ua+1900).toString().substring(2),"%Y":f=>f.ua+1900,"%z":f=>{f=f.Yb;var r=0<=f;f=Math.abs(f)/60;return(r?"+":"-")+String("0000"+(f/60*100+f%60)).slice(-4)},"%Z":f=>f.ac,"%%":()=>"%"};c=c.replace(/%%/g,"\x00\x00");for(t in k)c.includes(t)&&(c=c.replace(new RegExp(t,"g"),k[t](d)));
c=c.replace(/\0\0/g,"%");t=Fc(c);if(t.length>a)return 0;Ba.set(t,b);return t.length-1};Object.assign(Za.prototype,{get(b){return this.qa[b]},has(b){return void 0!==this.qa[b]},ub(b){var a=this.eb.pop()||this.qa.length;this.qa[a]=b;return a},xb(b){this.qa[b]=void 0;this.eb.push(b)}});$a=h.BindingError=class extends Error{constructor(b){super(b);this.name="BindingError"}};Q.qa.push({value:void 0},{value:null},{value:!0},{value:!1});Q.Za=Q.qa.length;
h.count_emval_handles=function(){for(var b=0,a=Q.Za;a<Q.qa.length;++a)void 0!==Q.qa[a]&&++b;return b};cb=h.PureVirtualError=bb("PureVirtualError");for(var Hc=Array(256),Ic=0;256>Ic;++Ic)Hc[Ic]=String.fromCharCode(Ic);db=Hc;h.getInheritedInstanceCount=function(){return Object.keys(hb).length};h.getLiveInheritedInstances=function(){var b=[],a;for(a in hb)hb.hasOwnProperty(a)&&b.push(hb[a]);return b};h.flushPendingDeletes=fb;h.setDelayFunction=function(b){gb=b;eb.length&&gb&&gb(fb)};
zb=h.InternalError=class extends Error{constructor(b){super(b);this.name="InternalError"}};Mb.prototype.isAliasOf=function(b){if(!(this instanceof Mb&&b instanceof Mb))return!1;var a=this.ba.ea.ca,c=this.ba.da,d=b.ba.ea.ca;for(b=b.ba.da;a.fa;)c=a.Ba(c),a=a.fa;for(;d.fa;)b=d.Ba(b),d=d.fa;return a===d&&c===b};
Mb.prototype.clone=function(){this.ba.da||Lb(this);if(this.ba.ya)return this.ba.count.value+=1,this;var b=Cb,a=Object,c=a.create,d=Object.getPrototypeOf(this),e=this.ba;b=b(c.call(a,d,{ba:{value:{count:e.count,wa:e.wa,ya:e.ya,da:e.da,ea:e.ea,ia:e.ia,la:e.la}}}));b.ba.count.value+=1;b.ba.wa=!1;return b};Mb.prototype["delete"]=function(){this.ba.da||Lb(this);this.ba.wa&&!this.ba.ya&&R("Object already scheduled for deletion");ob(this);qb(this.ba);this.ba.ya||(this.ba.ia=void 0,this.ba.da=void 0)};
Mb.prototype.isDeleted=function(){return!this.ba.da};Mb.prototype.deleteLater=function(){this.ba.da||Lb(this);this.ba.wa&&!this.ba.ya&&R("Object already scheduled for deletion");eb.push(this);1===eb.length&&gb&&gb(fb);this.ba.wa=!0;return this};Vb.prototype.Hb=function(b){this.jb&&(b=this.jb(b));return b};Vb.prototype.$a=function(b){this.ra&&this.ra(b)};Vb.prototype.argPackAdvance=8;Vb.prototype.readValueFromPointer=Fb;Vb.prototype.deleteObject=function(b){if(null!==b)b["delete"]()};
Vb.prototype.fromWireType=function(b){function a(){return this.Ea?Bb(this.ca.ma,{ea:this.Sb,da:c,la:this,ia:b}):Bb(this.ca.ma,{ea:this,da:b})}var c=this.Hb(b);if(!c)return this.$a(b),null;var d=yb(this.ca,c);if(void 0!==d){if(0===d.ba.count.value)return d.ba.da=c,d.ba.ia=b,d.clone();d=d.clone();this.$a(b);return d}d=this.ca.Gb(c);d=xb[d];if(!d)return a.call(this);d=this.Da?d.vb:d.pointerType;var e=rb(c,this.ca,d.ca);return null===e?a.call(this):this.Ea?Bb(d.ca.ma,{ea:d,da:e,la:this,ia:b}):Bb(d.ca.ma,
{ea:d,da:e})};Yb=h.UnboundTypeError=bb("UnboundTypeError");
var Kc={N:function(b,a,c){b=U(b);a=nb(a,"wrapper");c=S(c);var d=[].slice,e=a.ca,g=e.ma,m=e.fa.ma,l=e.fa.constructor;b=Ya(b,function(){e.fa.ib.forEach(function(k){if(this[k]===m[k])throw new cb(`Pure virtual function ${k} must be implemented in JavaScript`);}.bind(this));Object.defineProperty(this,"__parent",{value:g});this.__construct.apply(this,d.call(arguments))});g.__construct=function(){this===g&&R("Pass correct 'this' to __construct");var k=l.implement.apply(void 0,[this].concat(d.call(arguments)));
ob(k);var t=k.ba;k.notifyOnDestruction();t.ya=!0;Object.defineProperties(this,{ba:{value:t}});Cb(this);k=t.da;k=ib(e,k);hb.hasOwnProperty(k)?R(`Tried to register registered instance: ${k}`):hb[k]=this};g.__destruct=function(){this===g&&R("Pass correct 'this' to __destruct");ob(this);var k=this.ba.da;k=ib(e,k);hb.hasOwnProperty(k)?delete hb[k]:R(`Tried to unregister unregistered instance: ${k}`)};b.prototype=Object.create(g);for(var n in c)b.prototype[n]=c[n];return ab(b)},O:function(b){var a=Db[b];
delete Db[b];var c=a.Pa,d=a.ra,e=a.cb,g=e.map(m=>m.Kb).concat(e.map(m=>m.Vb));V([b],g,m=>{var l={};e.forEach((n,k)=>{var t=m[k],v=n.Ib,x=n.Jb,f=m[k+e.length],r=n.Ub,q=n.Wb;l[n.Eb]={read:G=>t.fromWireType(v(x,G)),write:(G,D)=>{var u=[];r(q,G,f.toWireType(u,D));Eb(u)}}});return[{name:a.name,fromWireType:function(n){var k={},t;for(t in l)k[t]=l[t].read(n);d(n);return k},toWireType:function(n,k){for(var t in l)if(!(t in k))throw new TypeError(`Missing field: "${t}"`);var v=c();for(t in l)l[t].write(v,
k[t]);null!==n&&n.push(d,v);return v},argPackAdvance:8,readValueFromPointer:Fb,ka:d}]})},C:function(){},K:function(b,a,c,d,e){var g=Jb(c);a=U(a);Ib(b,{name:a,fromWireType:function(m){return!!m},toWireType:function(m,l){return l?d:e},argPackAdvance:8,readValueFromPointer:function(m){if(1===c)var l=Ba;else if(2===c)l=Ca;else if(4===c)l=M;else throw new TypeError("Unknown boolean type size: "+a);return this.fromWireType(l[m>>g])},ka:null})},f:function(b,a,c,d,e,g,m,l,n,k,t,v,x){t=U(t);g=Z(e,g);l&&(l=
Z(m,l));k&&(k=Z(n,k));x=Z(v,x);var f=Xa(t);Ob(f,function(){Zb(`Cannot construct ${t} due to unbound types`,[d])});V([b,a,c],d?[d]:[],function(r){r=r[0];if(d){var q=r.ca;var G=q.ma}else G=Mb.prototype;r=Ya(f,function(){if(Object.getPrototypeOf(this)!==D)throw new $a("Use 'new' to construct "+t);if(void 0===u.sa)throw new $a(t+" has no accessible constructor");var O=u.sa[arguments.length];if(void 0===O)throw new $a(`Tried to invoke ctor of ${t} with invalid number of parameters (${arguments.length}) - expected (${Object.keys(u.sa).toString()}) parameters instead!`);
return O.apply(this,arguments)});var D=Object.create(G,{constructor:{value:r}});r.prototype=D;var u=new Pb(t,r,D,x,q,g,l,k);u.fa&&(void 0===u.fa.Ca&&(u.fa.Ca=[]),u.fa.Ca.push(u));q=new Vb(t,u,!0,!1);G=new Vb(t+"*",u,!1,!1);var I=new Vb(t+" const*",u,!1,!0);xb[b]={pointerType:G,vb:I};Wb(f,r);return[q,G,I]})},j:function(b,a,c,d,e,g,m){var l=ac(c,d);a=U(a);g=Z(e,g);V([],[b],function(n){function k(){Zb(`Cannot call ${t} due to unbound types`,l)}n=n[0];var t=`${n.name}.${a}`;a.startsWith("@@")&&(a=Symbol[a.substring(2)]);
var v=n.ca.constructor;void 0===v[a]?(k.va=c-1,v[a]=k):(Nb(v,a,t),v[a].ga[c-1]=k);V([],l,function(x){x=$b(t,[x[0],null].concat(x.slice(1)),null,g,m);void 0===v[a].ga?(x.va=c-1,v[a]=x):v[a].ga[c-1]=x;if(n.ca.Ca)for(const f of n.ca.Ca)f.constructor.hasOwnProperty(a)||(f.constructor[a]=x);return[]});return[]})},x:function(b,a,c,d,e,g,m,l){a=U(a);g=Z(e,g);V([],[b],function(n){n=n[0];var k=`${n.name}.${a}`,t={get(){Zb(`Cannot access ${k} due to unbound types`,[c])},enumerable:!0,configurable:!0};t.set=
l?()=>{Zb(`Cannot access ${k} due to unbound types`,[c])}:()=>{R(`${k} is a read-only property`)};Object.defineProperty(n.ca.constructor,a,t);V([],[c],function(v){v=v[0];var x={get(){return v.fromWireType(g(d))},enumerable:!0};l&&(l=Z(m,l),x.set=f=>{var r=[];l(d,v.toWireType(r,f));Eb(r)});Object.defineProperty(n.ca.constructor,a,x);return[]});return[]})},s:function(b,a,c,d,e,g){var m=ac(a,c);e=Z(d,e);V([],[b],function(l){l=l[0];var n=`constructor ${l.name}`;void 0===l.ca.sa&&(l.ca.sa=[]);if(void 0!==
l.ca.sa[a-1])throw new $a(`Cannot register multiple constructors with identical number of parameters (${a-1}) for class '${l.name}'! Overload resolution is currently only performed using the parameter count, not actual type info!`);l.ca.sa[a-1]=()=>{Zb(`Cannot construct ${l.name} due to unbound types`,m)};V([],m,function(k){k.splice(1,0,null);l.ca.sa[a-1]=$b(n,k,null,e,g);return[]});return[]})},a:function(b,a,c,d,e,g,m,l){var n=ac(c,d);a=U(a);g=Z(e,g);V([],[b],function(k){function t(){Zb(`Cannot call ${v} due to unbound types`,
n)}k=k[0];var v=`${k.name}.${a}`;a.startsWith("@@")&&(a=Symbol[a.substring(2)]);l&&k.ca.ib.push(a);var x=k.ca.ma,f=x[a];void 0===f||void 0===f.ga&&f.className!==k.name&&f.va===c-2?(t.va=c-2,t.className=k.name,x[a]=t):(Nb(x,a,v),x[a].ga[c-2]=t);V([],n,function(r){r=$b(v,r,k,g,m);void 0===x[a].ga?(r.va=c-2,x[a]=r):x[a].ga[c-2]=r;return[]});return[]})},e:function(b,a,c,d,e,g,m,l,n,k){a=U(a);e=Z(d,e);V([],[b],function(t){t=t[0];var v=`${t.name}.${a}`,x={get(){Zb(`Cannot access ${v} due to unbound types`,
[c,m])},enumerable:!0,configurable:!0};x.set=n?()=>{Zb(`Cannot access ${v} due to unbound types`,[c,m])}:()=>{R(v+" is a read-only property")};Object.defineProperty(t.ca.ma,a,x);V([],n?[c,m]:[c],function(f){var r=f[0],q={get(){var D=bc(this,t,v+" getter");return r.fromWireType(e(g,D))},enumerable:!0};if(n){n=Z(l,n);var G=f[1];q.set=function(D){var u=bc(this,t,v+" setter"),I=[];n(k,u,G.toWireType(I,D));Eb(I)}}Object.defineProperty(t.ca.ma,a,q);return[]});return[]})},J:function(b,a){a=U(a);Ib(b,{name:a,
fromWireType:function(c){var d=S(c);cc(c);return d},toWireType:function(c,d){return ab(d)},argPackAdvance:8,readValueFromPointer:Fb,ka:null})},p:function(b,a,c,d){function e(){}c=Jb(c);a=U(a);e.values={};Ib(b,{name:a,constructor:e,fromWireType:function(g){return this.constructor.values[g]},toWireType:function(g,m){return m.value},argPackAdvance:8,readValueFromPointer:dc(a,c,d),ka:null});Ob(a,e)},d:function(b,a,c){var d=nb(b,"enum");a=U(a);b=d.constructor;d=Object.create(d.constructor.prototype,{value:{value:c},
constructor:{value:Ya(`${d.name}_${a}`,function(){})}});b.values[c]=d;b[a]=d},y:function(b,a,c){c=Jb(c);a=U(a);Ib(b,{name:a,fromWireType:function(d){return d},toWireType:function(d,e){return e},argPackAdvance:8,readValueFromPointer:ec(a,c),ka:null})},m:function(b,a,c,d,e,g){var m=ac(a,c);b=U(b);e=Z(d,e);Ob(b,function(){Zb(`Cannot call ${b} due to unbound types`,m)},a-1);V([],m,function(l){Wb(b,$b(b,[l[0],null].concat(l.slice(1)),null,e,g),a-1);return[]})},l:function(b,a,c,d,e){a=U(a);-1===e&&(e=4294967295);
e=Jb(c);var g=l=>l;if(0===d){var m=32-8*c;g=l=>l<<m>>>m}c=a.includes("unsigned")?function(l,n){return n>>>0}:function(l,n){return n};Ib(b,{name:a,fromWireType:g,toWireType:c,argPackAdvance:8,readValueFromPointer:fc(a,e,0!==d),ka:null})},g:function(b,a,c){function d(g){g>>=2;var m=P;return new e(m.buffer,m[g+1],m[g])}var e=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][a];c=U(c);Ib(b,{name:c,fromWireType:d,argPackAdvance:8,readValueFromPointer:d},{Lb:!0})},
z:function(b,a){a=U(a);var c="std::string"===a;Ib(b,{name:a,fromWireType:function(d){var e=P[d>>2],g=d+4;if(c)for(var m=g,l=0;l<=e;++l){var n=g+l;if(l==e||0==L[n]){m=m?jc(L,m,n-m):"";if(void 0===k)var k=m;else k+=String.fromCharCode(0),k+=m;m=n+1}}else{k=Array(e);for(l=0;l<e;++l)k[l]=String.fromCharCode(L[g+l]);k=k.join("")}mb(d);return k},toWireType:function(d,e){e instanceof ArrayBuffer&&(e=new Uint8Array(e));var g="string"==typeof e;g||e instanceof Uint8Array||e instanceof Uint8ClampedArray||e instanceof
Int8Array||R("Cannot pass non-string to std::string");var m=c&&g?hc(e):e.length;var l=Jc(4+m+1),n=l+4;P[l>>2]=m;if(c&&g)gc(e,L,n,m+1);else if(g)for(g=0;g<m;++g){var k=e.charCodeAt(g);255<k&&(mb(n),R("String has UTF-16 code units that do not fit in 8 bits"));L[n+g]=k}else for(g=0;g<m;++g)L[n+g]=e[g];null!==d&&d.push(mb,l);return l},argPackAdvance:8,readValueFromPointer:Fb,ka:function(d){mb(d)}})},u:function(b,a,c){c=U(c);if(2===a){var d=mc;var e=nc;var g=oc;var m=()=>Da;var l=1}else 4===a&&(d=pc,e=
qc,g=rc,m=()=>P,l=2);Ib(b,{name:c,fromWireType:function(n){for(var k=P[n>>2],t=m(),v,x=n+4,f=0;f<=k;++f){var r=n+4+f*a;if(f==k||0==t[r>>l])x=d(x,r-x),void 0===v?v=x:(v+=String.fromCharCode(0),v+=x),x=r+a}mb(n);return v},toWireType:function(n,k){"string"!=typeof k&&R(`Cannot pass non-string to C++ string type ${c}`);var t=g(k),v=Jc(4+t+a);P[v>>2]=t>>l;e(k,v+4,t+a);null!==n&&n.push(mb,v);return v},argPackAdvance:8,readValueFromPointer:Fb,ka:function(n){mb(n)}})},Q:function(b,a,c,d,e,g){Db[b]={name:U(a),
Pa:Z(c,d),ra:Z(e,g),cb:[]}},P:function(b,a,c,d,e,g,m,l,n,k){Db[b].cb.push({Eb:U(a),Kb:c,Ib:Z(d,e),Jb:g,Vb:m,Ub:Z(l,n),Wb:k})},L:function(b,a){a=U(a);Ib(b,{Nb:!0,name:a,argPackAdvance:0,fromWireType:function(){},toWireType:function(){}})},v:function(b,a,c){b=S(b);a=nb(a,"emval::as");var d=[],e=ab(d);P[c>>2]=e;return a.toWireType(d,b)},w:function(b,a,c,d,e){b=uc[b];a=S(a);c=tc(c);var g=[];P[d>>2]=ab(g);return b(a,c,g,e)},i:function(b,a,c,d){b=uc[b];a=S(a);c=tc(c);b(a,c,null,d)},c:cc,h:function(b,a){var c=
wc(b,a),d=c[0];a=d.name+"_$"+c.slice(1).map(function(m){return m.name}).join("_")+"$";var e=xc[a];if(void 0!==e)return e;var g=Array(b-1);e=vc((m,l,n,k)=>{for(var t=0,v=0;v<b-1;++v)g[v]=c[v+1].readValueFromPointer(k+t),t+=c[v+1].argPackAdvance;m=m[l].apply(m,g);for(v=0;v<b-1;++v)c[v+1].zb&&c[v+1].zb(g[v]);if(!d.Nb)return d.toWireType(n,m)});return xc[a]=e},t:function(b){b=tc(b);return ab(h[b])},M:function(b,a){b=S(b);a=S(a);return ab(b[a])},n:function(b){4<b&&(Q.get(b).kb+=1)},o:function(b){return ab(tc(b))},
A:function(){return ab({})},q:function(b){var a=S(b);Eb(a);cc(b)},k:function(b,a,c){b=S(b);a=S(a);c=S(c);b[a]=c},r:function(b,a){b=nb(b,"_emval_take_value");b=b.readValueFromPointer(a);return ab(b)},b:()=>{ya("")},E:b=>{var a=L.length;b>>>=0;if(2147483648<b)return!1;for(var c=1;4>=c;c*=2){var d=a*(1+.2/c);d=Math.min(d,b+100663296);var e=Math;d=Math.max(b,d);a:{e=e.min.call(e,2147483648,d+(65536-d%65536)%65536)-za.buffer.byteLength+65535>>>16;try{za.grow(e);Ga();var g=1;break a}catch(m){}g=void 0}if(g)return!0}return!1},
F:(b,a)=>{var c=0;Ac().forEach(function(d,e){var g=a+c;e=P[b+4*e>>2]=g;for(g=0;g<d.length;++g)Ba[e++>>0]=d.charCodeAt(g);Ba[e>>0]=0;c+=d.length+1});return 0},G:(b,a)=>{var c=Ac();P[b>>2]=c.length;var d=0;c.forEach(function(e){d+=e.length+1});P[a>>2]=d;return 0},H:()=>52,B:function(){return 70},I:(b,a,c,d)=>{for(var e=0,g=0;g<c;g++){var m=P[a>>2],l=P[a+4>>2];a+=8;for(var n=0;n<l;n++){var k=L[m+n],t=Bc[b];0===k||10===k?((1===b?ta:ua)(jc(t,0)),t.length=0):t.push(k)}e+=l}P[d>>2]=e;return 0},D:(b,a,c,
d)=>Gc(b,a,c,d)};(function(){function b(c){J=c=c.exports;za=J.R;Ga();Ha=J.X;Ja.unshift(J.S);Ma--;h.monitorRunDependencies&&h.monitorRunDependencies(Ma);if(0==Ma&&(null!==Na&&(clearInterval(Na),Na=null),Oa)){var d=Oa;Oa=null;d()}return c}var a={a:Kc};Ma++;h.monitorRunDependencies&&h.monitorRunDependencies(Ma);if(h.instantiateWasm)try{return h.instantiateWasm(a,b)}catch(c){ua("Module.instantiateWasm callback failed with error: "+c),ba(c)}Va(a,function(c){b(c.instance)}).catch(ba);return{}})();
var mb=b=>(mb=J.T)(b),Jc=b=>(Jc=J.U)(b),lb=b=>(lb=J.V)(b);h.__embind_initialize_bindings=()=>(h.__embind_initialize_bindings=J.W)();h.dynCall_jiji=(b,a,c,d,e)=>(h.dynCall_jiji=J.Y)(b,a,c,d,e);h.dynCall_viijii=(b,a,c,d,e,g,m)=>(h.dynCall_viijii=J.Z)(b,a,c,d,e,g,m);h.dynCall_iiiiij=(b,a,c,d,e,g,m)=>(h.dynCall_iiiiij=J._)(b,a,c,d,e,g,m);h.dynCall_iiiiijj=(b,a,c,d,e,g,m,l,n)=>(h.dynCall_iiiiijj=J.$)(b,a,c,d,e,g,m,l,n);
h.dynCall_iiiiiijj=(b,a,c,d,e,g,m,l,n,k)=>(h.dynCall_iiiiiijj=J.aa)(b,a,c,d,e,g,m,l,n,k);var Lc;Oa=function Mc(){Lc||Nc();Lc||(Oa=Mc)};
function Nc(){function b(){if(!Lc&&(Lc=!0,h.calledRun=!0,!Aa)){Wa(Ja);aa(h);if(h.onRuntimeInitialized)h.onRuntimeInitialized();if(h.postRun)for("function"==typeof h.postRun&&(h.postRun=[h.postRun]);h.postRun.length;){var a=h.postRun.shift();Ka.unshift(a)}Wa(Ka)}}if(!(0<Ma)){if(h.preRun)for("function"==typeof h.preRun&&(h.preRun=[h.preRun]);h.preRun.length;)La();Wa(Ia);0<Ma||(h.setStatus?(h.setStatus("Running..."),setTimeout(function(){setTimeout(function(){h.setStatus("")},1);b()},1)):b())}}
if(h.preInit)for("function"==typeof h.preInit&&(h.preInit=[h.preInit]);0<h.preInit.length;)h.preInit.pop()();Nc();


  return moduleArg.ready
}

);
})();
export default Rive;