import React from "react";
import { Route, Routes } from "react-router-dom";

// ... other imports
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Invite from "./components/InviteForm";

function App() {
  return (
    <div className="h-screen w-screen bg-gray-800 flex flex-col justify-between text-white">
      <Navbar className="" />
      <div className="flex-grow sm:p-4">
        <div className="bg-gray-900 h-full w-full rounded-lg p-2">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/invite" element={<Invite />} />

            {/* If you have a 404 page or want to handle unmatched routes */}
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </div>
      </div>
      <Footer className="" />
    </div>
  );
}
export default App;
