import React from "react";
import InviteForm from "./FormTemplate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { Suspense, lazy } from "react";
import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import RiveWrapper from "./RiveWrapper";

const Invite = () => {
  const navigate = useNavigate();
  const questions = [
    {
      id: "q1",
      text: "What should we call you?",
      description: "",
      type: "open",
    },
    {
      id: "q2",
      text: "What's your email",
      description: "Please use your official school address",
      type: "open",
    },

    // Add more questions as needed
  ];

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (answers) => {
    setLoading(true);
    try {
      await fetch(
        "https://akzr96vob4.execute-api.us-east-1.amazonaws.com/dev/record/user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(answers),
        }
      );
      console.log("success adding events");
      navigate("/calendar");
    } catch (error) {
      console.error(error);
      window.alert("An error occurred: " + error.message);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="flex flex-col  bg-teal-900">
        <RiveWrapper className="animate-slow-appear" />
        <div className="text-white self-center md:text-2xl text-2xl font-bold z-10 -translate-y-44 animate-pulse animate-slow-appear">
          Loading..
        </div>
      </div>
    );
  }

  return (
    <div className="h-full  overflow-hidden align-middle items-center">
      <div className="h-full flex items-center justify-center m-auto sm:mx-10 mx-6 overflow-hidden">
        <InviteForm questions={questions} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default Invite;
